import React, {useEffect, useState} from 'react';
import {Spin, Alert, Form, Input, Button, message} from 'antd';
import {Navigate} from 'react-router-dom';
import Oidc from 'oidc-client';

import Common from '../../lib/Common';
import {login} from '../../utils/AuthService'
import APIServices from '../../lib/APIServices';
import './login.css';

const {getData} = Common;
const {getAuthorize} = APIServices;
const Login = () => {

    const getMenu = () => {
        const knowledgeGraph = 5;
        getAuthorize(knowledgeGraph).then((res) => {
            if (typeof res == 'string') {
                message.warn(res);
                return false;
            } else {
                if (res.length > 0) {
                    sessionStorage.setItem("menuList", JSON.stringify(res));
                    const docurl = window.location.origin + res[0].urlAddress;
                    window.location.href = docurl;
                } else {
                    message.warn('暂无菜单权限！');
                    return false;
                }
            }
        })
        // request(`/Authorize/module`, {}, 'GET')
    }
    useEffect(() => {
        if (!sessionStorage.getItem("token") && !getData('id_token')) {
            login();
        } else if (sessionStorage.getItem("token")) {
            // const docurl = window.location.origin + '/home/echarts';
            // window.location.href = docurl;
            getMenu()
        } else {

            let mgr = new Oidc.UserManager({
                userStore: new Oidc.WebStorageStateStore(),
                loadUserInfo: true,
                filterProtocolClaims: true
            });

            mgr.signinRedirectCallback().then(function (user) {
                sessionStorage.setItem("token", user.access_token);
                sessionStorage.setItem("userName", user.profile.name);
                sessionStorage.setItem("profile", JSON.stringify(user.profile));
                // const docurl = window.location.origin + '/home/echarts';
                // window.location.href = docurl;
                getMenu()
            }).catch(function (err) {
                console.log(err);
            });

        }
    })
    return (
        <div className='login'>
            <div className='box'><Spin tip="" size="large"></Spin></div>
        </div>
    )
};

export default Login;
