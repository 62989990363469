export default {
    // graph data
    GET_GRAPH_DATA: 'get_graph_data',
    SET_TOP_SEARCH_VALUE: 'set_top_search_value',
    SET_SEARCH_ID: 'set_search_id',
    CATEGORIES: [
        { key: 'root', name: '搜索值', color: '#5470c6' },
        { key: 'tName', name: '方药技法名称', color: '#91cc75' },
        { key: 'definition', name: '定义', color: '#fac858' },
        { key: 'rane', name: '使用范围', color: '#ee6666' },
        { key: 'operationMethod', name: '操作方法', color: '#73c0de' },
        { key: 'commonDiseases', name: '常见疾病', color: '#3ba272' },
        { key: 'contraindicationsText', name: '禁忌症', color: '#fc8452' },
        { key: 'precautionsText', name: '注意事项', color: '#9a60b4' },
        { key: 'exceptionHandlerText', name: '异常情况及处理措施', color: '#ea7ccc' },
        { key: 'clinicalApplication', name: '临床应用', color: '#aac858' },
        { key: 'composition', name: '成分', color: '#13c0de' },
        { key: 'configurationMethod', name: '配置方法', color: '#3Ecc75' },
    ],
    DISABLE_CLICK_key: ['composition', 'operationMethod', 'commonDiseases'],
    DISABLE_CLICK: ['禁忌症', '注意事项', '异常情况及处理措施', '成分', '常见疾病', '操作方法', '临床应用', '搜索值', '民族特色应用'],
    STEPS: [
        {
            title: '语义分析中',
            content: 'First-content',
        },
        {
            title: '数据搜索中',
            content: 'Second-content',
        },
        {
            title: '数据整合中',
            content: 'Last-content',
        },
    ],
    LEFT_CLICK: 0,
    RIGHT_CLICK: 2,
};