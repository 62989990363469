import Item from "antd/lib/list/Item";
import Constants from "./Constants";

// 处理知识图谱数据
const getGraphData = (textDatas: any, _searchValue: string) => {
    const CATEGORIES_KEY: any = {
        'root': 0,
        'tName': 1,
        'definition': 2,
        'rane': 3,
        'operationMethod': 4,
        'commonDiseases': 5,
        'contraindicationsText': 6,
        'precautionsText': 7,
        'exceptionHandlerText': 8,
        'clinicalApplication': 9,
        'composition': 10,
        'configurationMethod': 11
    }
    // group level
    const levels = {
        level0: 1,
        level1: 1,
        level2: 2,
        level3: 3,
        level4: 4,
        level5: 5,
        level6: 6,
        levelMax: 10,
        levelRoot: 99
    }

    const values = {
        level0: 30,
        level1: 25,
        level2: 20,
        level3: 15,
        level4: 10,
        level5: 5,
        levelMax: 50,
        levelRoot: 60
    }
    const _initKey = [
        { name: '成分', id: 'composition', group: levels.level0, value: values.level0, },
        { name: '操作方法', id: 'operationMethod', group: levels.level0, value: values.level0 },
        { name: '常见疾病', id: 'commonDiseases', group: levels.level0, value: values.level0 },
        { name: '禁忌症', id: 'contraindicationsText', group: levels.level0, value: values.level0, },
        { name: '注意事项', id: 'precautionsText', group: levels.level0, value: values.level0, },
        { name: '异常情况及处理措施', id: 'exceptionHandlerText', group: levels.level0, value: values.level0 }
    ]
    // 是不是多条数据， 多条数据添加搜索内容为根节点
    const AddRoot = Boolean(textDatas.length > 1);
    // 将数据组成树结构
    const arrayToTree = (arr: any, pid: string) => {
        const treeData = arr.reduce((res: any, current: any) => {
            if (current['parentId'] === pid) {
                current.children = arrayToTree(arr, current['id']);
                return res.concat(current);
            }
            return res;
        }, []);
        return treeData;
    };
    const dataMap = textDatas && textDatas.map((textData: any, rootIndex: number) => {
        const _data: any = textData;
        const _id = textData.id;
        let _textDataNode: any = [];
        // 遍历对象,组成node的值
        for (let key in _data) {
            // 数据格式是数组的
            if (_data[key] instanceof Array) {
                const _existName: any = _initKey.find((item: any) => item.id === key);
                _textDataNode.push({ ..._existName, id: `${key}-${rootIndex}`, key });
                // composition 成分的特殊处理
                if (key !== 'composition') {
                    const treeData = arrayToTree(_data[key], '');
                    // 父子嵌套处理
                    const getNodes = (arr: any, value: number) => {
                        arr.map((arrItem: any) => {
                            _textDataNode.push({
                                name: `${arrItem.title}:${arrItem.describeText}`,
                                id: arrItem.id,
                                parentId: arrItem.parentId ? arrItem.parentId : `${key}-${rootIndex}`,
                                group: levels.level6,
                                value,
                                key,
                                describeHtml: arrItem.describeHtml,

                            });
                            if (arrItem.children.length > 0) {
                                getNodes(arrItem.children, value - 5);
                            }
                        })
                    }
                    getNodes(treeData, values.level2);
                    // console.log(_testNodes, '======_testNodes')
                    // const hostTitle = _data[key].filter((host: any) => !host.parentId);
                    // hostTitle.map((item: any, index: number) => {
                    //     // 添加主标题
                    //     _textDataNode.push({
                    //         name: `${item.title}:${item.describeText}`,
                    //         id: `${key}-${rootIndex}-${index}`,
                    //         group: levels.level2,
                    //         value: values.level2,
                    //         key,
                    //     })
                    //     _data[key].map((host: any, hostIndex: number) => {
                    //         //  添加子标题
                    //         if (item.id === host.parentId) {
                    //             _textDataNode.push({
                    //                 name: `${host.title}:${host.describeText}`,
                    //                 id: `${key}-${rootIndex}-${index}-${hostIndex}`,
                    //                 group: levels.level3,
                    //                 value: values.level3,
                    //                 key,
                    //             })
                    //         }
                    //     });
                    // })
                } else {
                    _data[key].map((item: any, index: number) => {
                        const name = '民族特色应用';
                        const featuredUse = item.featuredUse && item.featuredUse.split('_')|| [];
                        _textDataNode.push({
                            name: item.yName,
                            id: `${key}-${rootIndex}-${index}`,
                            group: levels.level2,
                            value: values.level2,
                            key,
                            tId: item.id,
                            tName: 'yName',
                            tLabel: '药名'
                        }, {
                            name: item.source,
                            id: `${key}-${rootIndex}-${index}-1`,
                            group: levels.level3,
                            value: values.level3,
                            key,
                            tId: item.id,
                            tName: 'source',
                            tLabel: '来源'
                        }, {
                            name: item.functionIndications,
                            id: `${key}-${rootIndex}-${index}-2`,
                            group: levels.level3,
                            value: values.level3,
                            key,
                            tId: item.id,
                            tName: 'functionIndications',
                            tLabel: '功效治疗'
                        }, {
                            name: name,
                            id: `${key}-${rootIndex}-${index}-3`,
                            group: levels.level3,
                            value: values.level3,
                            key,
                            tId: item.id
                        });
                        // 展示民族医特色应用
                        featuredUse.map((use: string, useIndex: number) => {
                            _textDataNode.push({
                                name: use,
                                id: `${key}-${rootIndex}-${index}-3-${useIndex}`,
                                group: levels.level5,
                                value: values.level5,
                                key,
                                allFeaturedUse: item.featuredUse,
                                tName: 'featuredUse',
                                tId: item.id,
                                tLabel: '民族医特色应用'
                            })
                        })

                    })
                }
            } else if (key.includes('Text')) {
                // 富文本字段的处理
                const _existName: any = _initKey.find((item: any) => item.id === key);
                // 
                _textDataNode.push({ ..._existName, id: `${key}-${rootIndex}`, key, category: CATEGORIES_KEY[key] });
                // 根据换行符切割字符, 先替换<br/> 为&& ， 然后去掉便签， 再将&& 替换<br/>， 实现保留<br/>分割
                const _htmlKey = key.replace('Text', 'Html');
                const _brStringBefore = _data[_htmlKey] && _data[_htmlKey].replace(/<br\/>/g, '&&');
                const removeStringTab = _brStringBefore && _brStringBefore.replace(/<[^>]+>/g, '');
                const _brStringAfter = removeStringTab && removeStringTab.replace(/&&/g, '<br/>');
                const _splitTag = '<br/>'
                const _textArr = _brStringAfter && _brStringAfter.split(_splitTag) || [];

                _textArr.map((item: any, itemIndex: number) => {
                    item && _textDataNode.push({
                        name: item,
                        id: `${key}-${rootIndex}-${itemIndex}`,
                        group: levels.level4,
                        value: values.level4,
                        key,
                        tId: _id,
                        allValue: _data[_htmlKey], // 带入全部值， 为了修改全部值
                    })
                })
            } else {
                // delete null and html
                if (_data[key] !== null && key !== 'id' && key !== 'type' && !key.includes('Html')) {
                    _textDataNode.push({
                        name: _data[key],
                        id: key == 'tName' ? _id : `${key}-${rootIndex}`,
                        group: key == 'tName' ? (levels.levelMax * (rootIndex + 1)) : levels.level1,
                        value: key == 'tName' ? values.levelMax : values.level1,
                        category: CATEGORIES_KEY[key],
                        key,
                        tId: _id,
                        type: textData.type
                    })
                }

            }
        };
        _textDataNode = _textDataNode.map((item: any) => {
            return {
                ...item,
                type: textData.type
            }
        })
        // 遍历node组成link的值
        let _textDataLinks: any = _textDataNode && _textDataNode.map((item: any) => {
            if (item.group == levels.level0) {
                // level0， 节点的一层节点， defined, 父节点tName id
                return {
                    source: item.id,
                    target: textData.id,
                    value: 10
                }
            } else if (item.group == levels.level1) {
                // level0， 节点的一层节点， 数组类型，自定义id, 父节点tName id
                return {
                    source: item.id,
                    target: textData.id,
                    value: 10
                }
            } else if (item.group == levels.level2 || item.group == levels.level4) {
                // level2, 字段是数组类型的值,或者text 富文本的title yName
                return {
                    source: item.id,
                    target: `${item.id.split('-')[0]}-${item.id.split('-')[1]}`,
                    value: 8
                }
            } else if (item.group == levels.level3) {
                // level3, 字段是数组类型或者text 富文本的详情值
                return {
                    source: item.id,
                    target: `${item.id.split('-')[0]}-${item.id.split('-')[1]}-${item.id.split('-')[2]}`,
                    value: 8
                }
            } else if (item.group == levels.level5) {
                // level3, 字段是数组类型或者text 富文本的详情值
                return {
                    source: item.id,
                    target: `${item.id.split('-')[0]}-${item.id.split('-')[1]}-${item.id.split('-')[2]}-${item.id.split('-')[3]}`,
                    value: 6
                }
            } else if (item.group == levels.level6) {
                // level6, 树结构
                return {
                    source: item.id,
                    target: item.parentId,
                    value: 6
                }
            } else if (item.group % 10 == 0 && AddRoot) {
                // 多条数据，给tName 添加根节点
                return {
                    source: item.id,
                    target: 'rootMax',
                    value: 15
                }
            }
        });
        // 过滤数组的空值,否则render 报错
        _textDataLinks = _textDataLinks.filter((item: any) => item)
        return {
            nodes: _textDataNode,
            links: _textDataLinks,
        }
    })
    // 将数组展开为一维数组
    const nodes: any = dataMap.map((item: any) => item.nodes).flat(1);
    const links: any = dataMap.map((item: any) => item.links).flat(1);
    // 将根节点添加在数组首位
    if (_searchValue !== '' && _searchValue !== 'click' && AddRoot) {
        nodes.unshift({
            name: _searchValue,
            id: 'rootMax',
            group: levels.levelRoot, //根节点
            value: values.levelRoot,
            key: 'root',
        });
    }

    return {
        nodes,
        links,
        target: textDatas.length > 1 ? _searchValue : textDatas[0].id
    }
};
const getData = (name: any) => {
    let urlArr = window.location.href.split("#");
    let data = '';
    if (urlArr.length > 1) {
        let dataArr = urlArr[1].split("&");
        for (let i = 0; i < dataArr.length; i++) {
            let url = dataArr[i].split("=");
            if (url[0] == name) {
                data = url[1];
                return data;
            }
        }
    }
    return data;
}
export default {
    getGraphData,
    getData
};