import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import { BrowserRouter, Routes, Route, Navigate, Link } from 'react-router-dom';

import Left from './components/Left/Left';
import Right from './components/Right/Right';
import RightEcharts from './components/Right/RightEcharts';
import AvatarDropdown from './components/AvatarDropdown/index';
import SearchInputGlobal from './components/SearchInputGlobal/SearchInputGlobal';
import './layout.css';


interface DefaultRootState {
    global: {
        graphData: any,
        searchValue: string
    }
}
const { Header, Footer, Sider, Content } = Layout;
const ILayout = () => {
    const [type, setType] = useState<number>(1);
    return (
        <Layout className='page-container'>
            <Header className='header'>
                <span className='title'>民族医知识图谱平台</span>
                <SearchInputGlobal />
                <AvatarDropdown />
                {/* <div className='version-tab'>
                    <Link to={{
                        pathname: '/home/3D'
                    }} >
                        <Button type={type == 1 ? 'primary' : 'default'} onClick={() => setType(1)}>3D版本</Button>
                    </Link>
                    <Link target="_blank" to={{
                        pathname: '/home/echarts',
                    }}>
                        <Button type={type == 2 ? 'primary' : 'default'} onClick={() => setType(2)}>Echarts版本</Button>
                    </Link>

                </div> */}
            </Header>
            <Content className="container">
                <Left />
                <Routes>
                    <Route path='3D' element={<Right />} />
                    <Route path='echarts' element={<RightEcharts />} />
                </Routes>
            </Content>

        </Layout>
    )
};

export default ILayout;
