import { createAction, handleActions } from 'redux-actions';
import { notification } from 'antd';
import APIServices from '../../lib/APIServices';
import CONSTANTS from '../../lib/Constants';
import COMMON from '../../lib/Common';

const { GET_GRAPH_DATA, SET_TOP_SEARCH_VALUE, SET_SEARCH_ID } = CONSTANTS;
const { getGraphData } = COMMON;
// initialState
const initialState = {
    graphData: {},
    searchValue: '',
    searchId: '',
};

interface Action {
    type: string
    payload?: any
}

type State = typeof initialState

export default handleActions(
    {
        [GET_GRAPH_DATA](state: State, action: Action) {
            let graphData = {};
            if (!action.payload.length) {
                graphData = {};
                setTimeout(() => {
                    notification.info({
                        message: '没有查询到数据',
                    });
                }, 3500)
            } else {
                graphData = getGraphData(action.payload, state.searchValue);
            }

            return {
                ...state,
                graphData,
            };
        },
        [SET_TOP_SEARCH_VALUE](state: State, action: Action) {
            return {
                ...state,
                searchValue: action.payload
            };
        },
        [SET_SEARCH_ID](state: State, action: Action) {
            return {
                ...state,
                searchId: action.payload
            };
        },
    },
    initialState
);

export const actions = {
    getTreeText: createAction(GET_GRAPH_DATA, async (params: { type: string, searchValue?: string | any, searchId?: string | any }) => {
        const { type, searchValue, searchId } = params;
        let _data = [];
        let result = {};
        if (type === 'search') {
            _data = await APIServices.getTreeText(searchValue);
        } else {
            _data = await APIServices.getTreeTextById(searchId);
        }
        return _data;
    }),
    setTopSearchValue: createAction(SET_TOP_SEARCH_VALUE),
    setSearchId: createAction(SET_SEARCH_ID),
};
