// import store from './storageUtils';
import axios from 'axios';
import { logout } from './AuthService';
import { notification } from 'antd';
import CONFIG from './Config';
const CONN_TIMEOUT = 15000;
const CONN_TIMEOUT_ERROR_MSG = 'Your device is having trouble while connecting to our services. Please check your internet connection and try again.';
// const ClientToken = `Basic ${CONFIG.ClientAuth}`;
const ClientToken = ``

declare module 'axios' {
    export interface AxiosResponse<T = any> extends Promise<T> { }
}

declare module 'axios' {
    interface AxiosInstance {
        (config: any): Promise<any>
    }
}

const axiosService = axios.create({
    baseURL: CONFIG.AdminHost,
    timeout: CONN_TIMEOUT,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
});

axiosService.defaults.responseType = 'json';
// axiosService.defaults.isRetryRequest = false
axiosService.defaults.validateStatus = (status: number) => status < 500;

axiosService.interceptors.request.use(
    async (config: any) => {
        const accessToken = sessionStorage.getItem('token');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            window.location.href = '/login'
        }

        return config;
    },
    (err: any) => Promise.reject(err)
);

const responseInterceptorSuccess = (response: any) => {
    if (!response) {
        throw 'Network Error';
    }
    if (response.status >= 200 && response.status < 400) {
        if (response.data) {
            const { data } = response;
            return data;
        }
    } else if (response.status === 401) {
        sessionStorage.removeItem("id_token");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userName");
        notification.error({
            message: '登录过期',
            placement: 'topRight',
        });
        window.location.href = '/login'
        return Promise.reject('401: No Unauthorized');
    } else if (response.status === 403) {
        notification.error({
            message: 'No Permission',
            placement: 'topRight',
        });
        logout();
        return Promise.reject('403: No Permission');
    } else if (response.status === 404) {
        notification.error({
            message: 'Not Found',
            placement: 'topRight',
        });
        return Promise.reject('404: Not Found');
    }
};

const responseInterceptorError = (error: any) => new Promise((resolve, reject) => {
    if (error.message === 'Network Error') {
        return reject('Network Error');
    } else {
        if (error.code === 'ECONNABORTED') {
            return reject(CONN_TIMEOUT_ERROR_MSG);
        }
        return reject(error);
    }
});

axiosService.interceptors.response.use(
    (response: any) => {
        const data = responseInterceptorSuccess(response);
        const { headers: { count } } = response;
        if (data) {
            return data;
        }
    },
    (error: any) => responseInterceptorError(error)
);

export default axiosService;
