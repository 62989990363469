import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Input,
    Select,
} from 'antd';

import { actions as globalAction } from '../../../../redux/modules/global';
import '../Left/left.css'
import './SearchInputGlobal.css'
const { Option } = Select;

const { getTreeText, setTopSearchValue } = globalAction;

const SearchInputGlobal = () => {
    // 方药ID || 技法ID
    const [searchType, setSearchType] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [count, setCount] = useState<number>(0);
    const { searchId } = useSelector((state: any) => ({
        searchId: state.global.searchId,
    }));
    const dispatch = useDispatch();
    const onSearch = (value: any) => {
        setCount(count + 1);
        setSearchValue(value);
    }
    useEffect(() => {
        if (searchValue) {
            dispatch(getTreeText({ type: 'search', searchValue }))
            dispatch(setTopSearchValue(searchValue))
        } else {
            if (count) {
                dispatch(getTreeText({ type: 'click', searchId }));
            }
        }

    }, [searchValue, count])
    return (<div className='search-container-global'>
        <Input.Group compact>
            {/* <Select defaultValue="0" style={{ width: 100 }} onChange={(_type) => setSearchType(Number(_type))}>
                <Option value="0">药名</Option>
                <Option value="1">病名</Option>
            </Select> */}
            <Input.Search allowClear style={{ width: '75%', fontSize: '16px' }} defaultValue={searchValue} onSearch={onSearch} placeholder='请输入症状或技法名称' />
        </Input.Group>
    </div>)
};

export default SearchInputGlobal;