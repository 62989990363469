import React, { useEffect, useState } from 'react';
import {
    Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import ForceGraph3D from 'react-force-graph-3d';
import SpriteText from 'three-spritetext';
import InitGraphData from '../../../../Mock/graphData';

import getGraphData from '../../../../Mock/textData';
import APIServices from '../../../../lib/APIServices';
import Constants from '../../../../lib/Constants';
import '../../layout.css';
import './right.css';

const { getTreeText } = APIServices;
const { STEPS } = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;
interface DefaultRootState {
    global: {
        graphData: any,
        searchValue: string
    }
}
const Right = () => {
    const [canvasWidth, setCanvasWidth] = useState<number>(0)
    const [canvasHeight, setCanvasHeight] = useState<number>(0)
    const [graphData, setGraphData] = useState<any>({});
    const [target, setTarget] = useState<string>('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [current, setCurrent] = useState(0);
    const { graphRes, searchValue } = useSelector((state: DefaultRootState) => ({
        graphRes: state.global.graphData,
        searchValue: state.global.searchValue
    }));

    useEffect(() => {
        setCanvasWidth(document.body.clientWidth * 0.8);
        // 减去头部的距离
        setCanvasHeight(document.body.clientHeight - 66);
    }, [target])
    // get global search
    useEffect(() => {
        setGraphData(graphRes);
        if (JSON.stringify(graphRes) !== '{}') {
            setTarget(graphRes.target)
        }
    }, [graphRes, searchValue])
    useEffect(() => {
        setLoadingStatus(true);
        setTimeout(() => {
            setCurrent(1);
        }, 0)
    }, [searchValue, target])

    useEffect(() => {
        if (current > STEPS_Start && current < STEPS_End - 1) {
            setTimeout(() => {
                setCurrent(current + 1)
            }, current * 0)
        }
        if (current == STEPS_End - 1) {
            setTimeout(() => {
                setLoadingStatus(false)
                setCurrent(0);
            }, current * 0)
        }

    }, [current]);
    return (
        <div className="right-container echartsRadar" style={{ width: canvasWidth, height: canvasHeight, backgroundColor: '#000' }}>
            {
                graphData.nodes && <ForceGraph3D graphData={graphData}
                    nodeAutoColorBy="group"
                    nodeRelSize={20}
                    nodeVal={(node: any) => node.size * 0.05}
                    nodeThreeObject={(node: any) => {
                        let text = '';
                        if (node.group % 10 === 0) {
                            // 根节点全部显示
                            text = node.name
                        } else {
                            // 显示字数控制
                            text = node.name.length > 5 ? node.name.slice(0, 5) : node.name;
                        }
                        const sprite = new SpriteText(text || '无');
                        sprite.color = node.color;
                        // 根节点 group = 10
                        sprite.textHeight = node.group === 99 ? 20 : (node.group % 10 === 0 ? 15 : 10);
                        return sprite;
                    }}
                    onNodeDragEnd={node => {
                        node.fx = node.x;
                        node.fy = node.y;
                        node.fz = node.z;
                    }}
                    width={canvasWidth}
                    height={canvasHeight}
                />
            }
        </div>
    )
};

export default Right;
