import React, {useCallback, useState} from 'react';
import {LogoutOutlined, SettingOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Menu, Spin} from 'antd';

import HeaderDropdown from '../HeaderDropdown/index';
import styles from './index.less';
import {logout} from '../../../../utils/AuthService';
import ChangePassword from './ChangePassword';
const AvatarDropdown = () => {
    const [openModal, setOpenModal] = useState(false);
    const onMenuClick = (event) => {
        const {key} = event;

        if (key === 'logout') {
            logout();
        }
        if (key === 'reset') {
            setOpenModal(true)
        }
    }
    const menuItems = [{
        key: 'reset',
        icon: <SettingOutlined />,
        label: '修改密码'
    }, {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: '退出登录'
    }];
    const menuHeaderDropdown = (
        <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick} items={menuItems} />
    );
    return (
        <div>
            <HeaderDropdown overlay={menuHeaderDropdown}>
                <span className={`${styles.action} ${styles.account}`} style={{zIndex: 3}}>
                    {
                        sessionStorage.getItem("portrait") ? <Avatar size="small" className={styles.avatar} src={sessionStorage.getItem("portrait") || ''} alt="avatar" />
                            : <Avatar size="small" className={styles.avatar} icon={<UserOutlined />} alt="avatar" />
                    }&nbsp;
                    <span className={`${styles.name} anticon`} style={{color: '#fff'}}>{sessionStorage.getItem("userName") || sessionStorage.getItem("testName") || '测试用户'}</span>
                </span>
            </HeaderDropdown>
            {
                openModal
                &&
                <ChangePassword openModal={openModal} onCloseModal={() => setOpenModal(false)} profile={sessionStorage.getItem("profile")} />
            }
        </div>
    );
};

export default AvatarDropdown;
