import axios from '../utils/axiosSetting';
import CONFIG_HOST from '../utils/Config';
const {rightHost} = CONFIG_HOST;

export default {
    getTreeList() {
        return axios.get(`/SearchData/tree`);
    },
    getTreeText(value: string) {
        return axios.get(`/SearchData/es/${value}`);
    },
    getTreeTextById(id: string) {
        return axios.get(`/SearchData/${id}`);
    },
    // 获取权限
    getAuthorize(value: string) {
        // return axios.get(`/Authorize/module`);
        return axios.get(`/Permissions/${value}/all`, {
            baseURL: rightHost
        });
    },
    // update tree data
    updateTreeData(params: any) {
        const { tid, ttype, tname, dname, tvalue, tvaluetext } = params;
        return axios.post(`/ModifyData/zstp?tid=${tid}&ttype=${ttype}&tname=${tname}&dname=${dname || ' '}&tvalue=${tvalue}&tvaluetext=${tvaluetext || ' '}`);
    },
    changeUserPassword(userId: string, value: string, ftpValue: any) {
        return axios.post(`/User/${userId}/${value}/${ftpValue}/reset`, {}, {
            baseURL: rightHost,
        });
    },
    // 获取民族医数据 
    getMinZuYiData() {
        return axios.get(`/DiseaseInformation/mzysjzd`,);
    },
};
