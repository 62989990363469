import React, { Component } from 'react';
import { Provider } from 'react-redux';

import Index from './pages/index/index';
import configureStore from './redux/configStore';
const reduxStore = configureStore();
class App extends Component<any> {
    render() {
        return (
            <Provider store={reduxStore as any}>
                <Index />
            </Provider >
        );
    }
}

export default App;
