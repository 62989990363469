import React, { useState } from 'react';
import {
    Input,
} from 'antd';
import TreeMenu from './TreeMenu';

import './left.css'
import '../../layout.css';

const Left = () => {
    const [treeValue, setTreeValue] = useState('')
    const [count, setCount] = useState<number>(0);
    return (
        <div className="left-container">
            {/* <Input.Group compact>
                <Input.Search placeholder='搜索' allowClear className='tree-input' defaultValue="" onSearch={(_value) => { setCount(count + 1); setTreeValue(_value) }} />
            </Input.Group> */}

            <TreeMenu />
        </div>
    )
};

export default Left;
