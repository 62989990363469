import React, { useEffect, useRef, useState } from 'react';
import { Spin, notification, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactEChartsCore from 'echarts-for-react/lib/core';;
import * as echarts from 'echarts/core';
import { actions as globalAction } from '../../../../redux/modules/global';
import InitGraphData from '../../../../Mock/graphData';
import Constants from '../../../../lib/Constants';
import EditNode from '../EditNode/EditNode';

import {
    BarChart,
    GraphChart,
} from 'echarts/charts';
import {
    GridComponent,
    GraphicComponent,
    ToolboxComponent,
    TooltipComponent,
    TitleComponent,
    LegendComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import './right.css';
// Register the required components
echarts.use(
    [GraphChart, LegendComponent, TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer]
);
interface DefaultRootState {
    global: {
        graphData: any,
        searchValue: string,
        searchId: string
    }
}

interface Props {
    type: number
}

const { getTreeText } = globalAction;
const { CATEGORIES, STEPS, RIGHT_CLICK, DISABLE_CLICK } = Constants;
const STEPS_Start = 0;
const STEPS_End = STEPS.length;
const RightEcharts: React.FC = () => {
    const [canvasWidth, setCanvasWidth] = useState<number>(0);
    const [canvasHeight, setCanvasHeight] = useState<number>(0);
    const [graphData, setGraphData] = useState<any>({});
    const [nodes, setNodes] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [target, setTarget] = useState<string>('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentNode, setCurrentNode] = useState({});
    const [currentTitle, setCurrentTitle] = useState('');
    const [currentLabel, setCurrentLabel] = useState('');
    const [options, setOptions] = useState({});
    const { graphRes, searchValue, searchId } = useSelector((state: DefaultRootState) => ({
        graphRes: state.global.graphData,
        searchValue: state.global.searchValue,
        searchId: state.global.searchId,
    }));
    const dispatch = useDispatch();
    const onChartReady = (charts: any) => {

    }
    const formatterCustom = (params: any) => {
        const { name } = params.data;
        const _splitFlag = ['。', '。\n', '<br/>'];
        return name
    };

    const getRender = (data: any) => {
        if (JSON.stringify(data) !== '{}') {
            const { nodes: _nodeData } = data;
            if (_nodeData.length) {
                const _nodes = data.nodes.map((node: any) => {
                    const colors: any = CATEGORIES.find((item: any) => item.key === node.key)
                    delete node.group;
                    node.key === 'tName' && setCurrentTitle(node.name);
                    return {
                        ...node,
                        symbolSize: node.value,
                        category: node.key,
                        itemStyle: {
                            // color: colors.color
                        }
                    }
                });
                setNodes(_nodes);
                const categoriesKey = Array.from(new Set(_nodes.map((node: any) => node.key)));
                let _categories: any = CATEGORIES.filter((item: any) => categoriesKey.includes(item.key));
                _categories = _categories.map((item: any) => {
                    return {
                        name: item.key,
                        itemStyle: {
                            color: item.color
                        }
                    }
                })
                setCategories(_categories)
            }
        }

    }
    const option = {
        tooltip: {
            show: true,
            formatter: formatterCustom,
            extraCssText: 'width: 300px; white-space:pre-wrap'
        },

        legend: {
            show: true,
            selectedMode: 'multiple',
            data: categories && categories.map((a: any) => {
                return a.name;
            }),
            formatter: (params: any) => {
                let _category: any = CATEGORIES.find((item: any) => item.key === params);
                return _category.name;
            }
        },
        series: [
            {
                name: '知识图谱',
                type: 'graph',
                layout: 'force',
                nodes: nodes,
                links: graphRes.links,
                categories: categories,
                roam: true,
                animation: false,
                emphasis: {
                    focus: 'adjacency',
                    label: {
                        position: 'right',
                        show: true
                    }
                },
                label: {
                    show: true,
                    position: 'right',
                    formatter: (params: any) => {
                        const { data } = params;
                        if(data.name) {
                            return data.name.length > 5 ? data.name.slice(0, 5) : data.name;
                        } else {
                            return ''
                        }
                       
                    }
                },
                force: {
                    repulsion: 100,
                    layoutAnimation: false, //停止动画
                },
                zoom: searchValue.length > 0 && searchValue !== 'click' ? 1 : 2,
            }
        ]
    };

    const getLabel = (key: any) => {
        const target: any = CATEGORIES.find((item) => item.key == key);
        if (target) {
            return target.name
        } else {
            return '标题'
        }
    }
    const onClickChart = (params: any, e: any) => {
        const { data, dataType } = params;
        if (params.event.event.button === RIGHT_CLICK && dataType === 'node') {
            if (DISABLE_CLICK.includes(params.name) || data.key === 'tName' || data.key === 'root' || data.describeHtml == "") {
                notification.info({
                    message: '固定标题禁止修改',
                    placement: 'topRight',
                });
            } else {
                params.event.event.preventDefault();
                setCurrentLabel(getLabel(data.key))
                showModal();
                setCurrentNode(data)
            }

        }
    }
    // remove right click default event menu
    document.oncontextmenu = () => false;
    const EventsDict = {
        'mousedown': onClickChart,
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    // 取消
    const onCancel = () => {
        setIsModalVisible(false);
        setCurrentNode({});
    };
    // 提交关闭
    const onClose = () => {
        setIsModalVisible(false);
        setLoadingStatus(true);
        setCurrentNode({})
        setTimeout(() => {
            dispatch(getTreeText({ type: 'click', searchId }));
        }, 3000)
    };

    useEffect(() => {
        setCanvasWidth(document.body.clientWidth * 0.8);
        // 减去头部的距离
        setCanvasHeight(document.body.clientHeight - 100);
        // getRender();
    }, [target]);

    useEffect(() => {

        if (JSON.stringify(graphRes) !== '{}') {
            setGraphData(graphRes);
            setTarget(graphRes.target)
            getRender(graphData);
        }
    }, [graphRes, graphData])

    useEffect(() => {
        getRender(graphData);
        setLoadingStatus(true);

    }, [target])

    useEffect(() => {
        setLoadingStatus(true);

    }, [searchValue])

    return <div className='echartsRadar' style={{ width: canvasWidth, height: canvasHeight }}>
        {
            JSON.stringify(graphRes) !== '{}' ?
                <ReactEChartsCore
                    echarts={echarts}
                    option={option}
                    notMerge={false}
                    lazyUpdate={true}
                    style={{ width: canvasWidth, height: canvasHeight }}
                    onChartReady={onChartReady}
                    onEvents={EventsDict}
                /> :
                <Empty className='tip' />


        }
        {
            isModalVisible && <EditNode
                visible={isModalVisible}
                onClose={onClose}
                node={currentNode}
                label={currentLabel}
                title={currentTitle}
                onCancel={onCancel}
            />
        }
    </div>;
};

export default RightEcharts;