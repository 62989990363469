import React from 'react';
import 'antd/dist/antd.min.css'; // or 'antd/dist/antd.less'
import { HashRouter, Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from '../login/login';
import ILayout from '../layout/layout';

const Index = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<Navigate to='login' />} />
                <Route path='/home/*' element={<ILayout />} >
                </Route>
                <Route path='/login' element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Index;
