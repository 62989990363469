import React, { useEffect, useRef, useState } from 'react';
import { Spin, Modal, Form, Input, message } from 'antd';
import APIServices from '../../../../lib/APIServices'
import Constants from '../../../../lib/Constants';
import Weditor from '../../../../components/wEditor/index'
interface Props {
    visible: boolean,
    onClose: () => void,
    onCancel: () => void,
    node: any,
    title: string,
    label: string,
}

const { updateTreeData } = APIServices;
const { CATEGORIES } = Constants;
const { TextArea } = Input;
const treeArrayData = ['operationMethod', 'commonDiseases'];
const isArrayKey = ['composition', 'operationMethod', 'commonDiseases'];
const isShowWeditorKey = ['contraindicationsText', 'precautionsText', 'exceptionHandlerText'];
const EditNode = (props: Props) => {
    const { visible, onCancel, onClose, node, title, label } = props;
    const [form] = Form.useForm();
    const [isArea, setIsArea] = useState<boolean>(true);
    const [treeLabel, setTreeLabel] = useState<string>('');
    const [tvaluetext, setTvaluetext] = useState<string>('');
    const [tvalue, setTvalue] = useState<string>('');
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [treeDefaultHtml, setTreeDefaultHtml] = useState('');
    const editorC = (name: any, val: any) => {
        // html
        setTvalue(val)
        form.setFieldsValue({
            [name]: val
        })

    }
    const editorText = (name: any, text: any) => {
        // text
        setTvaluetext(text)
        node['allValue'] = text;
    }

    useEffect(() => {
        if (JSON.stringify(node) !== '{}') {
            // key 是否包含Text
            if (!node.key.includes('Text')) {
                // key 是否是数组
                if (isArrayKey.includes(node.key)) {
                    // key 是否是 composition成分
                    if (node.key.includes('composition')) {
                        setIsArea(true)
                    } else {
                        setIsArea(false)
                    }
                } else {
                    setIsArea(true)
                }
            } else {
                setIsArea(false)
            }

            if (treeArrayData.includes(node.key)) {
                const _data = node.name.split(':');
                setTreeLabel(_data[0]);
                form.resetFields();
                form.setFieldsValue({
                    title: _data[0],
                    describeHtml: node.describeHtml,
                })
            } else {
                node.tLabel && setTreeLabel(node.tLabel);
                form.resetFields();
                form.setFieldsValue({
                    [node.key]: node.name
                })
            }
        }

    }, [node])

    return (
        <Modal
            title={title}
            visible={visible}
            okText="提交"
            cancelText="取消"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        setLoadingStatus(true)
                        // update node data
                        let updateInfo: any = {
                            tid: node.tId,
                            ttype: node.type,
                        }
                        // 文本框类似于平级
                        if (!node.key.includes('Text')) {
                            // 是不是数组字段的
                            if (isArrayKey.includes(node.key)) {
                                // 树结构
                                updateInfo = {
                                    ...updateInfo,
                                    tid: node.key === 'composition' ? node.tId : node.id,
                                    tname: node.key === 'composition' ? node.tName : 'describeHtml',
                                    dname: `${node.key}`, // 数组的字段名
                                    tvalue: node.key === 'composition' ? values[node.key] : tvalue, //value
                                    tvaluetext: node.key === 'composition' ? ' ' : tvaluetext, //text
                                }
                                // 名族医特色应用
                                if (node.tName && node.tName === 'featuredUse') {
                                    let featuredUse = node.allFeaturedUse.split('_');
                                    const targetIndex = node.id.split('-');
                                    console.log(targetIndex[targetIndex.length - 1]);
                                    featuredUse = featuredUse.map((text: string, index: number) => {
                                        if (index === Number(targetIndex[targetIndex.length - 1])) {
                                            // index相等 替换文本值
                                            return values[node.key]
                                        } else {
                                            return text;
                                        }
                                    })
                                    // 组合成新的字符串
                                    updateInfo.tvalue = featuredUse.join('_');
                                    // updateInfo.dname = node.tName;
                                }

                            } else {
                                // 普通的文本框 例如定义
                                updateInfo = {
                                    ...updateInfo,
                                    tname: `${node.key}`,
                                    dname: ' ', // 数组的字段名
                                    tvalue: values[node.key], //value
                                    tvaluetext: ' ', //text
                                }
                            }

                        } else {
                            // key是含有Text,是富文本输入
                            updateInfo = {
                                ...updateInfo,
                                tname: `${(node.key).replace('Text', 'Html')}`,
                                dname: ' ', // 数组的字段名
                                tvalue: tvalue, //html
                                tvaluetext: tvaluetext, //text
                            }
                        }

                        updateTreeData(updateInfo)
                        // onCreate(values);
                        setTimeout(() => {
                            message.success('修改成功')
                            setLoadingStatus(false)
                            onClose();
                        }, 500)

                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}>
            <Spin tip={'更新数据'} size="large" spinning={loadingStatus} className='tip'>
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    {
                        treeArrayData.includes(node.key) && node.describeHtml &&
                        <Form.Item
                            name={'describeHtml'}
                            label={treeLabel}
                            rules={[{ required: true, message: 'Please input the title of collection!' }]}
                        >
                            <Weditor name={'describeHtml'} textName={'describeText'} editorC={editorC} editorText={editorText} initialHtml={node.describeHtml} />

                        </Form.Item>
                    }
                    {
                        !treeArrayData.includes(node.key)
                        &&
                        <Form.Item
                            name={node.key}
                            label={treeLabel || label}
                            rules={[{ required: true, message: 'Please input the title of collection!' }]}
                        >
                            {
                                isArea
                                    ? <TextArea
                                        name={node.key}
                                        placeholder={`${node.name}`}
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        defaultValue={node.name}
                                    />
                                    : <Weditor name={`${(node.key).replace('Text', 'Html')}`} textName={`${(node.key)}`} editorC={editorC} editorText={editorText} initialHtml={node.allValue} />
                            }
                        </Form.Item>
                    }

                </Form>
            </Spin>

        </Modal>
    )
}

export default EditNode;
