import { useEffect, useState } from 'react';
import { Menu, Button, Select, Row, Col, Tree } from 'antd';
import { useDispatch } from 'react-redux';
import { CarryOutOutlined } from '@ant-design/icons';

import APIServices from '../../../../lib/APIServices';
import { actions as globalAction } from '../../../../redux/modules/global';

const { Option } = Select;
const { getTreeList, getMinZuYiData } = APIServices
const { getTreeText, setTopSearchValue, setSearchId } = globalAction;
interface Props {
    // target: string
};

const TreeMenu = (props: Props) => {
    const { } = props;
    const [basicTreeData, setBasicTreeData] = useState<any>([]);
    const [treeData, setTreeData] = useState<any>([]);
    const [currentKey, setCurrentKey] = useState<any>('');
    const [getCount, setGetCount] = useState<number>(0);
    const dispatch = useDispatch();
    const onSelect = (currentRow: any, allRow: any) => {
        const _id = currentRow[0];
        if (_id && _id.length > 30) {
            setCurrentKey(_id)
            dispatch(getTreeText({ type: 'click', searchId: _id }));
            dispatch(setTopSearchValue('click'));
            dispatch(setSearchId(_id))
        }
    }

    // 获取分类菜单
    const filterTreeData = (basicData: [{ name: string, id: string, itemInfo: [any] }]) => {
        const _filterData1 = basicData;
        // 设置菜单第一项为知识图谱数据
        if (_filterData1.length) {
            setCurrentKey(_filterData1[0].itemInfo[0].id)
            dispatch(getTreeText({ type: 'click', searchId: _filterData1[0].itemInfo[0].id }));
            dispatch(setSearchId(_filterData1[0].itemInfo[0].id));
        }
        setTreeData(_filterData1);
    };
    // 获取全部菜单
    const getTreeData = async () => {
        const _data: [{ name: string, id: string, itemInfo: [any] }] = await getTreeList();
        setBasicTreeData(_data);
        filterTreeData(_data);
    };
    const menuItem = (_menus: any, rName: string) => {
        const reg = new RegExp(rName, 'g');
        return _menus.map((_menu: any) => {
            return {
                title: String(_menu.tName.replace(reg, ' ')),
                key: _menu.id,
            }
        })
    }
    useEffect(() => {
        // 防抖
        if (!basicTreeData.length && getCount < 10) {
            setTimeout(() => {
                getTreeData();
                setGetCount(getCount + 1);
            }, getCount * 1000)
        }
    }, [basicTreeData]);


    return (<div className='menu-box'>
        {/* <Menu
            onClick={handleClick}
            style={{ width: '100%' }}
            selectedKeys={[currentKey]}
            mode="inline"
            items={}
        >
        </Menu> */}
        {
            treeData.length > 0
            &&
            <Tree
                defaultExpandAll={true}
                showLine={
                    {
                        showLeafIcon: false
                    }
                }
                showIcon={false}
                defaultSelectedKeys={[currentKey]}
                onSelect={onSelect}
                treeData={treeData.map((menu: any, index: number) => {
                    return {
                        title: menu.itemName,
                        key: menu.itemCode,
                        children: menu.itemInfo && menuItem(menu.itemInfo, menu.itemName),
                    }
                })}
            />
        }

    </div>)
};

export default TreeMenu;